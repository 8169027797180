import React from 'react'
import { graphql, Link } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import ContactUsGate from '../assets/images/contact-us-gate.jpg'

class ContactUs extends React.Component {
  render() {
    const siteTitle = 'Contact Us | J & J Gates Service and Design'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Contact Us</h1>
            <hr />
          </div>
        </div>
        <div className="container pt-5 d-padding-b text-justify">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <p>
                There are several Dallas-Fort Worth gate repair and installation
                companies to choose from, but we hope you will select J & J
                Gates Service and Design. We are appreciative of the opportunity
                to earn your business and want to prove it! Not only do we
                provide free estimates, will we complete the job efficiently and
                economically if hired. We will respect you and your home or
                business! We always leave the job site in neat order and pay
                attention to even the smallest of details. You can rest assured
                knowing that we have the experience required to do the job right
                the first time and that we will treat any installation or repair
                as if it were for our own property.
              </p>
            </div>
            <div className="text-center col-md-6">
              <LazyLoadImage
                className="img-fluid"
                src={ContactUsGate}
                alt="About Us Gate"
              />
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-12">
              <h4 className="mt-0">Excellent Customer Service</h4>
              <p>
                J & J Gates Service and Design offers excellent customer service
                and is easily accessible. We are available to answer any
                questions or concerns in a timely fashion. Since making our
                debut in 2002, we have received multiple positive reviews from
                our satisfied customers. Not only will we meet your gate
                installation and repair expectations, but we will also exceed
                them, so contact us today!
              </p>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-6">
              <h4 className="mt-0">Call Us</h4>
              <p>
                <strong>24/7 Services Available</strong> - Call{' '}
                <a href="tel:8174662794">(817)-466-2794</a> <br />
                <em>Emergency Services are 1.5x the Regular Rate</em>
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="mt-0">Hours of Operation</h4>
              <p>
                Monday - Friday: 8:00AM - 4:30PM
                <br />
                Weekends: By Appointment Only
              </p>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-12">
              <h4 className="mt-0">Office Addresses</h4>
              <div className="row flex-column-reverse flex-md-row">
                <div className="col address-wrapper">
                  <Link to="/locations/fort-worth-tx" className="address-head">
                    <p style={{ fontSize: `1.25rem` }}>Dallas Location</p>
                    <p>
                      325 North St. Paul Street #3100 Dallas, TX 75201
                      <br />
                      (817)-466-2794
                    </p>
                  </Link>
                </div>
                <div className="col address-wrapper">
                  <Link to="/locations/fort-worth-tx" className="address-head">
                    <p style={{ fontSize: `1.25rem` }}>Arlington Location</p>
                    <p>
                      2000 Camden Ct.
                      <br />
                      Arlington, TX 76013
                      <br />
                      (817)-466-2794
                    </p>
                  </Link>
                </div>
                <div className="col address-wrapper">
                  <Link to="/locations/fort-worth-tx" className="address-head">
                    <p style={{ fontSize: `1.25rem` }}>Fort Worth Location</p>
                    <p>
                      201 Main St., 6th Floor
                      <br />
                      Fort Worth, TX 76102
                      <br />
                      (817)-466-2794
                    </p>
                  </Link>
                </div>
                <div className="col address-wrapper">
                  <Link to="/locations/fort-worth-tx" className="address-head">
                    <p style={{ fontSize: `1.25rem` }}>Burleson Location</p>
                    <p>
                      12308 Rendon Rd Ste. 108
                      <br />
                      Burleson, Tx. 76028
                      <br />
                      (817)-466-2794
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactUs

export const ContactUsPageQuery = graphql`
  query ContactUsPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
